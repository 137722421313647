import React from 'react';
import style from './index.module.scss';
import logo from '../../assets/logo.png';
import li from '../../assets/linkedin.svg';
import {Link} from 'react-scroll';

const SideMenu = () => {
  return (
    <div className={style.menu}>
      <div className={style.logo_wrapper}>
        <img src={logo} className={style.logo} alt="efektyvumas"/>
        <div className={style.row}>
          <a className={style.tel} rel="noopener noreferrer" href="tel:+370 682 66131">T: +370 682 66131</a>
          <a className={style.linkedin} rel="noopener noreferrer"
             href="https://www.linkedin.com/in/svaj%C5%ABnas-ge%C4%8Das-565a01110/" target='_blank'>
            <img src={li} alt="ln" className={style.icon}/>
          </a>
        </div>
      </div>
      <div className={style.menu_items}>
        <Link activeClass="active" to="step1" spy={true} smooth={true} offset={0} duration={500}>Esamos situacijos
          vertinimas <span className={style.first}>1</span></Link>
        <Link activeClass="active" to="step2" spy={true} smooth={true} offset={0} duration={500}>Komandos
          mokymas <span>2</span></Link>
        <Link activeClass="active" to="step3" spy={true} smooth={true} offset={0} duration={500}>Procesų
          tobulinimas <span>3</span></Link>
        <Link activeClass="active" to="step4" spy={true} smooth={true} offset={0} duration={500}>Situacijos
          standartizavimas <span>4</span></Link>
        <Link activeClass="active" to="step5" spy={true} smooth={true} offset={0}
              duration={500}>Rezultatas <span>5</span></Link>
        <Link activeClass="active" to="methods" spy={true} smooth={true} offset={0} duration={500}>Tobulinimo metodai,
          įrankiai</Link>
        <Link activeClass="active" to="contacts" spy={true} smooth={true} offset={0} duration={500}>Kontaktai</Link>
      </div>
    </div>
  );
};

export default SideMenu;
