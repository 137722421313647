import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';

const Container = (props) => {
  return (
    <section className={`${style.container} ${props.className}`}>
      {props.children}
    </section>
  );
};

Container.propTypes = {
  className:PropTypes.string
}

export default Container;
