import React from 'react';
import style from "./index.module.scss";
import Container from "../UI/Container";
import Heading from "../UI/Heading";
import image from '../../assets/p2.svg';
import {Element} from 'react-scroll';

const Hero = () => {
  return (
    <Element className={style.hero_section} name='hero'>
      <Container className={style.section}>
        <div className={style.image}>
          <img src={image} alt="konvejeris"/>
        </div>
        <Heading as='h1'>
          Gamybos procesų
          <br/>
          tobulinimas, efektyvinimas!
        </Heading>
        <h3>
          Taikoma sukaupta <span>daugiametė gamybinė patirtis</span>,
          <br/>
          įgytos žinios ir praktika.
        </h3>
        <h3>
          <span>Žvilgsnis kitu kampu</span>, šiuolaikinis, sisteminis požiūris į gamybos
          ir visos tiekimo grandinės valdymą, vadovaujantis moderniomis
          vadybos sistemomis.
        </h3>
        <h3>
          <span>Proveržis</span> gamybos ir įmonės rezultatams, darbuotojų įtraukimas į
          tobulinimą
        </h3>
        <div className={style.row1}>
          <p>
            Efektyvus — veiksmingas, kuriantis, duodantis reikiamus arba geriausius rezultatus.
          </p>
          <p>
            Efektyvumas – patirtų sąnaudų ir gauto rezultato santykis.
          </p>
        </div>
        <div className={style.row2}>
          <p>
            Efektyvumo sąvoką galima pritaikyti gana plačiai, detalizuojant efektyvumo paskaičiavimo galimybes. Galima
            vertinti kaip efektyviai naudojami ištekliai produktui pagaminti, kaip efektyviai naudojami pastatai,
            įranga,
            priemonės, o taip pat ir laiką, per kurį pagaminamas produktas.
          </p>
          <p>
            Efektyvumu galima įvertinti viską, kas kuria vertę klientui, savininkui, darbuotojui, aplinkai.
          </p>
          <p>
            Galima efektyvumą tiesiog susieti su įmonės pelningumu, kuo įmonės procesai efektyvesni, tuo pelnas didesnis.
          </p>
        </div>
      </Container>
    </Element>
  );
};

export default Hero;
