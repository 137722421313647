import React from 'react';
import Container from "../UI/Container";
import style from './index.module.scss';
import Heading from "../UI/Heading";
import image from "../../assets/p3.svg";
import {Element} from 'react-scroll';

const Step1 = () => (
  <Element className={style.section} name='step1'>
    <div className={style.image}>
      <img src={image} alt="konvejeris"/>
    </div>
    <Container>
      <Heading number={1}>
        Esamos situacijos
        <br/>
        vertinimas
      </Heading>
      <ul>
        <li>
          Esamų procesų vertinimas darbo vietoje, veiksmų matavimas, nekuriančios
          vertės veiklos, elgsenos paieška, kurią vėliau padedame pašalinti.
        </li>
        <li>
          Fiksuojame ir pateikiame tobulintinus ir sektinus pavyzdžius.
        </li>
        <li>
          Sudarome vertės srauto žemėlapį, randame siaurąsias grandinės vietas.
        </li>
        <li>
          Padedame nusistatyti viziją, pagrindinius įmonės veiklos rodiklius.
        </li>
        <li>
          Atliekame palaikančių turtą ir techniką tarnybų veiklos analizę.
        </li>
        <li>
          Teikiame rekomendacijas veiklos valdymui, planavimui, gerinimui.
        </li>
        <div className={style.desc}>
          <p>
            Pirmasis ir pats pagrindinis žingsnis – esamos situacijos įvertinimas.
          </p>
          <p>
            Galutinis rezultatas stipriai priklauso nuo to ką matome, pastebime, fiksuojame ir kokių toliau
            priemonių imsimės situacijos gerinimui.
          </p>
          <p>
            Todėl labai svarbu nuodugniai, išsamiai ir teisingai sudėlioti faktus, pateikti juos įmonės
            darbuotojams, vadovams, susidaryti tobulinimo veiksmų planą.
          </p>
        </div>
      </ul>
    </Container>
  </Element>
);

export default Step1;
