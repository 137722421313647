import React from 'react';
import arrow from '../../../assets/arrow.svg';
import arrowLight from '../../../assets/arrow-light.svg';
import style from './index.module.scss';
import PropTypes from 'prop-types';

const Arrows = ({amount, color, className}) => {
  let arrows = []
  for (let i = 0; i < amount; i++) {
    arrows.push(
      color === 'light'
        ? <img key={i} src={arrow} alt="" className={style.arrow}/>
        : <img key={i} src={arrowLight} alt="" className={style.arrow}/>
    )
  }
  return (
    <div className={`${style.container} ${className}`}>
      {arrows}
    </div>
  );
};
Arrows.propTypes = {
  amount: PropTypes.number.isRequired,
  color: PropTypes.oneOf(['light', 'regular']).isRequired,
  className:PropTypes.string
}
export default Arrows;
