import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

const Heading = ({children, number, as}) => {
  return (
    <div className={style.heading}>
      {number && <span className={style.number}>{number}</span>}
      <div>
        {as==='h1' && <h1 className={style.title}>{children}</h1>}
        {!as && <h2 className={style.title}>{children}</h2>}
      </div>
    </div>
  );
};
Heading.propTypes = {
  children:PropTypes.string.isRequired,
  number:PropTypes.number,
  as:PropTypes.string,
}
export default Heading;
