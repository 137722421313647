import React from 'react';
import style from "./index.module.scss";
import curve from "../../../assets/curve.svg";

const BottomGutter = () => {
  return (
    <div className={style.gutter}>
        <div className={`${style.part} ${style.part_one}`}>
          <span>LEAN</span>
        </div>
        <div className={`${style.part} ${style.part_two}`}>
          <span>GVS (MOS)</span>
          <strong>Gamybos valdymo sistema</strong>
        </div>
        <div className={`${style.part} ${style.part_three}`}>
          <span>Projektų</span>
          <span>valdymas</span>
        </div>
        <div className={`${style.part} ${style.part_four}`}>
          <span>Koučingas</span>
        </div>
    </div>
  );
};

export default BottomGutter;
