import React from 'react';
import Heading from "../UI/Heading";
import Container from "../UI/Container";
import style from './index.module.scss';
import image from "../../assets/p6.svg";
import {Element} from 'react-scroll';

const Step4 = () => (
  <Element className={style.section} name='step4'>
    <div className={style.image}>
      <img src={image} alt="konvejeris"/>
    </div>
    <Container>
      <Heading number={4}>
        Situacijos
        <br/>
        standartizavimas
      </Heading>
      <ul>
        <li>
          Standartizuojame darbą, padedame kurti standartus.
        </li>
        <li>
          Sukuriame mechanizmą, palaikantį ir nuolat gerinantį pasiektą rezultatą.
        </li>
        <li>
          Pateikiame visą reikiamą medžiagą situacijos stabilizavimui, nuolatiniam procesų gerinimui.
        </li>
      </ul>
      <div className={style.desc}>
        <p>
          Šiame etape suderinami, sinchronizuojami ir užfiksuojami proceso veiksmai. Standartizavus procesus užkertamas kelias grįžti į prieš tai buvusią situaciją
        </p>
        <p>
          Darbo procesas optimizuojamas, ištiesinamas, pasiekiama, kad darbas vyktų ritmiškai, pagal užduotą taktą, sukuriamos „žaidimo“ taisyklės, savikontrolės mechanizmas
        </p>
        <p>
          Sumažinami, panaikinami nuostoliai, švaistymas, sutaupomi gamybos resursai, perskirstomos ir
          patikslinamos funkcijos, sutrumpinamas operacijai reikalingas laikas.
        </p>
      </div>
    </Container>
  </Element>
);

export default Step4;
