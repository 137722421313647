import React from 'react';
import style from "./index.module.scss";
import Heading from "../UI/Heading";
import Container from "../UI/Container";
import image from "../../assets/p8.svg";
import Arrows from "../UI/Arrows";
import {Element} from 'react-scroll';

const Methods = () => {
  return (
    <Element className={style.section} name='methods'>
      <Container>
        <Heading>
          Tobulinimo metodai,
          <br/>
          įrankiai
        </Heading>
        <div className={style.row}>
          <h4 className={style.with_margin}>
            Viena iš pažangiausių ir populiariausių šių dienų procesų efektyvinimo sistemų - <span>LEAN</span>. Tai ne
            tik sistema, tai valdymo filosofija, nuolat eliminuojanti nekuriančią vertės veiklą.
          </h4>
          <h4>
            Pagrindiniai tikslai – efektyvinti, optimizuoti resursų, išteklių panaudojimą, didinant vertę klientui ir
            konkurencinį pranašumą.
          </h4>
          <h4>
            Naudojamos pagrindinės LEAN efektyvinimo priemonės:<span>Asaichi, VSM, 5S, Kaizen, SW, TPM, PDCA, A3, SMED, Kanban.</span>
          </h4>
        </div>
        <div className={style.row}>
          <h4>
            Viena iš sėkmingiausių ir daugiausiai naudos davusių metodikų – <span>gamybos valdymo sistema</span>
            (GVS) angl. <span>„Manufacturing operation system“ (MOS)</span>.
          </h4>
          <h4>
            Ši metodika leidžia suprasti ir suvaldyti gamybos procesus, informacijos pasidalinimą.
          </h4>
        </div>
        <div className={style.desc}>
          <p>
            Pagal esamą problematiką ar darbo vietos specifiką pasirankami skirtingi nuolatinio tobulinimo įrankiai,
            kurie padeda sutvarkyti, susisteminti procesus ir pasiekti norimą rezultatą. Tai gali būti informacijos
            srautų susisteminimas; pagrindinių veiklos rodiklių nusistatymas, sekimas, monitoringas; optimalus atsargų
            lygis; darbo standartizavimas, darbuotojų organizavimas; perėjimų organizavimas; technikos priežiūra;
            tvarkos palaikymas, darbo vietos vizualizacija; problemų iš esmės sprendimas; pasiūlymai patobulinimui –
            darbuotojų įtraukimas į veiklos tobulinimą
          </p>
          <p>
            Tačiau lygiagrečiai naudojamos ir kitos metodikos, technikos padedančios vykdyti projektus, spręsti
            problemas,
            ugdyti darbuotojus - <span>Projektų valdymas, Koučingas (Coaching)</span>.
          </p>
        </div>

      </Container>
      <aside>
        <div className={style.content}>
          <h5><span>Šalinami</span><span>nuostoliai</span></h5>
          <img src={image} alt="konvejeris" className={style.image}/>
          <ul>
            <li>Transportavimas</li>
            <li>Atsargos</li>
            <li>Judėjimas</li>
            <li>Laukimas</li>
            <li>Pergaminimas</li>
            <li>Nepanaudotas <br/> žmogiškasis potencialas</li>
            <li className={style.with_padding}>Papildomas procesas</li>
            <li className={style.with_padding}>Defektai</li>
          </ul>
        </div>
        <Arrows amount={40} color='regular' className={style.arrows}/>
      </aside>
    </Element>
  );
};

export default Methods;
