import React from 'react';
import Heading from "../UI/Heading";
import Container from "../UI/Container";
import style from './index.module.scss';
import image from "../../assets/p5.svg";
import {Element} from 'react-scroll';

const Step3 = () => (
  <Element className={style.section} name='step3'>
    <div className={style.image}>
      <img src={image} alt="konvejeris"/>
    </div>
    <Container>
      <Heading number={3}>
        Procesų
        <br/>
        tobulinimas
      </Heading>
      <ul>
        <li>
          Kartu su darbuotojais taikome išmoktą medžiagą, įrankius praktikoje, atpažįstame ir šaliname nuostolius, švaistymą.
        </li>
        <li>
          Padedame susitvarkyti ir vizualizuoti darbo vietas, kad darbas būtų efektyvesnis, našesnis, būtų užtikrinta kokybė, sauga darbe.
        </li>
        <li>
          Sisteminame atsargų valdymą, efektyviname perėjimus.
        </li>
        <li>
          Sisteminame kasdienių ir įsisenėjusių problemų sprendimą, padedame rasti
          ir pašalinti esmines priežastis. Į problemų sprendimą įtraukiame visų pakopų
          darbuotojus.
        </li>
        <li>
          Diegiame darbuotojų pasiūlymų sistemą, kuri užtikrina nuolatinį tobulėjimą, procesų ir aplinkos gerinimą.
        </li>
      </ul>
      <div className={style.desc}>
        <p>
          Efektyvumą galima padidinti sumažinus gamybos ir tiekimo sąnaudas, sutaupius laiką ar
          medžiagas produktui pagaminti, paslaugai atlikti. Tačiau norint, kad tai nebūtų vienkartinis pasiekimas, rezultatas
          turi būti gerinamas nuolat. Nekuriančios vertės veiklos (nuostolių) eliminavimas turi tapti kasdienybe, įsitraukiant
          į nuolatinį procesų kūrimą ir tobulinimą.
        </p>
        <p>
          Taigi nuolatinis tobulinimas – tai kasdienis efektyvumo, produktyvumo kėlimas, kokybės gerinimas, efektyvus medžiagų, energetinių išteklių, pastatų (patalpų), technikos naudojimas.
        </p>
      </div>
    </Container>
  </Element>
);

export default Step3;
