import React from 'react';
import Heading from "../UI/Heading";
import Container from "../UI/Container";
import style from './index.module.scss';
import image from "../../assets/p7.svg";
import {Element} from 'react-scroll';

const Step5 = () => (
  <Element className={style.section} name='step5'>
    <div className={style.image}>
      <img src={image} alt="konvejeris"/>
    </div>
    <Container className={style.section}>
      <Heading number={5}>
        Rezultatas
      </Heading>
      <h4>
        Priklausomai nuo esamos situacijos ir nuo darbuotojų įsitraukimo į veiklos
        tobulinimą, pasiekiamas skirtingas rezultatas.
      </h4>
      <h5>
        Dažniausiai praktikoje jau pirmuose žingsniuose pasiekiamas gerėjimas:
      </h5>
      <ul>
        <li>
          sumažinami kaštai,
        </li>
        <li>
          sutrumpinamas produkto tiekimo terminas,
        </li>
        <li>
          padidinamas produktyvumas, efektyvumas, stabilizuojamas plano vykdymas,
        </li>
        <li>
          pagerinama produkto kokybė, sumažinamas brokas,
        </li>
        <li>
          sumažinami patiriami medžiagų nuostoliai,
        </li>
        <li>
          sumažinamas atsargų lygis, sugrąžinamos įšaldytos lėšos,
        </li>
        <li>
          sugeneruojama vieta sandėliavimui ir procesui,
        </li>
        <li>
          sutaupoma energija, gamtiniai ištekliai.
        </li>
      </ul>
      <div className={style.desc}>
        <p>
          Norint, kad geri rezultatai taptų realybe, būtinas sisteminis požiūris į darbo organizavimą, praktikoje išbandytų ir pasitvirtinusių metodų taikymas      </p>
        <p>
          Rezultatą galima pasiekti tik keičiant, tobulinant situaciją, o tai galima atlikti tik pakeitus požiūrį.
        </p>
      </div>
    </Container>
  </Element>
);

export default Step5;
