import React from 'react';
import SideMenu from "./components/SideMenu";
import Hero from "./components/Hero";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Arrows from "./components/UI/Arrows";
import UpperGutter from "./components/UI/UpperGutter";
import Methods from "./components/Methods";
import Contacts from "./components/Contacts";

function App() {
  let amount = 150;
  if(window.innerWidth<=375) amount=260;
  if(window.innerWidth<=600 && window.innerWidth>375) amount=240
  return (
    <div className="App">
      <SideMenu/>
      <UpperGutter/>
      <Arrows amount={amount} color='light'/>
      <Hero/>
      <Step1/>
      <Step2/>
      <Step3/>
      <Step4/>
      <Step5/>
      <Methods/>
      <Contacts/>
    </div>
  );
}

export default App;
