import React from 'react';
import Container from "../UI/Container";
import style from './index.module.scss';
import BottomGutter from "../UI/BottomGutter";
import li from "../../assets/linkedin-full.svg";
import profile from '../../assets/profile.jpg';
import {Element} from 'react-scroll';
import qr from '../../assets/qrc.png';

const Contacts = () => {
  return (
    <Element className={style.contacts} name='contacts'>
      <BottomGutter/>
      <Container className={style.container}>
        <img src={profile} alt="Svajūnas Gečas" className={style.photo}/>
        <h2>Svajūnas Gečas</h2>
        <h5>Gamybos ekspertas</h5>
        <a className={style.tel} rel="noopener noreferrer" href="tel:+370 682 66131">
          T: +370 682 66131
        </a>
        <a className={style.website} rel="noopener noreferrer"
           href="http://www.efektyvumas.com" target='_blank'>
          www.efektyvumas.com
        </a>
        <a className={style.linkedin} rel="noopener noreferrer"
           href="https://www.linkedin.com/in/svaj%C5%ABnas-ge%C4%8Das-565a01110/" target='_blank'>
          <img src={li} alt="ln" className={style.icon}/>
        </a>
        <img src={qr} alt="" className={style.qr}/>
        <p>
          Efektyvus, kuriantis vertę, ambicingas, patyręs vadovas, projektų valdymo
          ir nuolatinio tobulinimo ekspertas.
        </p>
        <p>
          Orientuotas į rezultatą, kūrybingas, entuziastingas, motyvuojantis ir
          vedantis komandą į sėkmę.
        </p>
        <p>
          Profesionaliai apmokytas Lean, projektų valdymo, koučingo.
        </p>
        <p>
          Virš 20 metų patirtis gamyboje įmonėse: UAB „Mars Lietuva“, UAB
          „Germanika“ (SBA grupė), UAB „Admen“, RAB Vičiūnai-Rus Atstovybė
          Lietuvoje (Vičiūnų grupė)
        </p>
      </Container>
    </Element>
  );
};

export default Contacts;
