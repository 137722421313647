import React from 'react';
import Heading from "../UI/Heading";
import Container from "../UI/Container";
import style from './index.module.scss';
import image from "../../assets/p4.svg";
import {Element} from 'react-scroll';

const Step2 = () => (
  <Element name='step2' className={style.section}>
    <div className={style.image}>
      <img src={image} alt="konvejeris"/>
    </div>
    <Container className={style.section}>
      <Heading number={2}>
        Komandos mokymas,
        <br/>
        ugdymas
      </Heading>
      <ul>
        <li>
          Išmokome darbuotojus atpažinti švaistymą, vertės nekuriančią veiklą.
        </li>
        <li>
          Mokome naudoti nuolatinio tobulinimo įrankius, savarankiškai taikyti juos atpažįstant
          nuostolius, tobulinant ir stabilizuojant procesus.
        </li>
        <li>
          Paliekame mokymų medžiagą, išmokome perteikti žinias visiems įmonės
          darbuotojams.
        </li>
        <li>
          Mokome darbuotojus ugdymo, lyderystės, informacijos pasidalinimo, efektyvaus
          problemų sprendimo.
        </li>
        <li>
          Pasidaliname daugiamete veikloje pritaikoma patirtimi.
        </li>
      </ul>
      <div className={style.desc}>
        <p>
          Į procesų tobulinimą ir nuostolių mažinimą įtraukiami visi įmonės darbuotojai.
        </p>
        <p>
          Žinantys, mokantys ir veikiantys darbuotojai – stipri įmonės komanda!
        </p>
        <p>
          Komandinis darbas, gera atmosfera – vienas pagrindinių įmonės veiklos variklių!
        </p>
      </div>
    </Container>
  </Element>
);

export default Step2;
